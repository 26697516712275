<template>
  <v-container id="data-tables" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-car-cog"
      inline
      class="px-5 py-3"
      :title="$t('vehicles_type')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" color="primary" elevation="2" @click.stop="dialog = true"
          >Nuevo Tipo</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog v-model="isSure" @click="cancel" />

      <!-- Dialogo Nuevo Agregar/Editar Tipo de Vehiculo en deshuso-->
      <v-dialog v-model="dialog1" max-width="650px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- FOTO -->
                <v-col cols="12">
                  <center>
                    <!-- <v-row align="end"> -->
                    <div>
                      <ThumborImage
                        v-if="editedItem.imagen"
                        :avatarSize="100"
                        :imageUrl="editedItem.imagen"
                      />
                      <ThumborImage v-else :avatarSize="100" :imageUrl="editedItem.imagen" />
                    </div>
                    <div>
                      <v-btn
                        block
                        class="text-none"
                        color="primary"
                        depressed
                        elevation="5"
                        rounded
                        small
                        :loading="isSelecting"
                        @click="onButtonClick"
                      >
                        <v-icon v-if="selectedFile === null" left> mdi-cloud-upload </v-icon>
                        <v-icon v-else left> mdi-cloud-check </v-icon>
                        {{ buttonText }}
                      </v-btn>
                      <input
                        accept="image/*"
                        class="d-none"
                        ref="uploader"
                        type="file"
                        @change="onFileChanged"
                      />
                    </div>
                    <!-- </v-row> -->
                  </center>
                </v-col>

                <!-- Traduccion tipo vehiculo -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Tipo*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.tipo.$model"
                    :error-messages="tipoErrors"
                    :hint="hintTextTipo"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.tipo.$model, 'tipo')"
                  >
                  </v-select>
                </v-col>

                <!-- Traduccion descripcion -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Descripción*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    :hint="hintTextDescripcion"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.descripcion.$model, 'descripcion')"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*campo requerido</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox v-model="checkbox" label="Crear otro" :disabled="isEditing"></v-checkbox>

            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogo WYSIWYG en actualmente en uso -->
      <v-dialog v-model="dialog" max-width="700px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- FOTO -->
                <v-col cols="12">
                  <center>
                    <!-- <v-row align="end"> -->
                    <div>
                      <ThumborImage
                        v-if="editedItem.imagen"
                        :avatarSize="100"
                        :imageUrl="editedItem.imagen"
                      />
                      <ThumborImage v-else :avatarSize="100" :imageUrl="editedItem.imagen" />
                    </div>
                    <div>
                      <v-btn
                        block
                        class="text-none"
                        color="primary"
                        depressed
                        elevation="5"
                        rounded
                        small
                        :loading="isSelecting"
                        @click="onButtonClick"
                      >
                        <v-icon v-if="selectedFile === null" left> mdi-cloud-upload </v-icon>
                        <v-icon v-else left> mdi-cloud-check </v-icon>
                        {{ buttonText }}
                      </v-btn>
                      <input
                        accept="image/*"
                        class="d-none"
                        ref="uploader"
                        type="file"
                        @change="onFileChanged"
                      />
                    </div>
                    <!-- </v-row> -->
                  </center>
                </v-col>

                <!-- Traduccion tipo vehiculo -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Tipo*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.tipo.$model"
                    :error-messages="tipoErrors"
                    :hint="hintTextTipo"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.tipo.$model, 'tipo')"
                  >
                  </v-select>
                </v-col>

                <!-- Traduccion descripcion -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Descripción*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    :hint="hintTextDescripcion"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.descripcion.$model, 'descripcion')"
                  >
                  </v-select>
                </v-col>

                <!-- Descripción idiomas -->

                <v-col cols="12">
                  <v-card class="mx-auto" outlined>
                    <v-tabs fixed-tabs background-color="primary" v-model="tab" slider-size="10">
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab> Español </v-tab>
                      <v-tab> Ingles </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <vue-editor
                          placeholder="Introduzca la información del vehiculo en español"
                          v-model="editedItem.descripcion_es"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-tab-item>
                      <v-tab-item>
                        <vue-editor
                          placeholder="Introduzca la información del vehiculo en ingles"
                          v-model="editedItem.descripcion_en"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>

                <!-- Descripcion larga idiomas -->
                <v-col cols="12">
                  <v-card class="mx-auto" outlined>
                    <v-tabs
                      fixed-tabs
                      background-color="primary"
                      v-model="tab_large"
                      slider-size="10"
                    >
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab> Español </v-tab>
                      <v-tab> Ingles </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab_large">
                      <v-tab-item>
                        <vue-editor
                          placeholder="Introduzca la información larga del vehiculo en español"
                          v-model="editedItem.descripcion_large_es"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-tab-item>
                      <v-tab-item>
                        <vue-editor
                          placeholder="Introduzca la información larga del vehiculo en ingles"
                          v-model="editedItem.descripcion_large_en"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <small>*campo requerido</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox v-model="checkbox" label="Crear otro" :disabled="isEditing"></v-checkbox>

            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de tipo de vehiculos  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="tipos"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.imagen`]="{ item }">
          <ThumborImage v-if="item.imagen" :imageUrl="item.imagen" :avatarSize="50" />
          <ThumborImage v-else />
        </template>
        <!-- Slot del boton de Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <!-- Slot actions editar y eliminar -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="tipos" :dataFields="fields" fileName="TiposVehiculo" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { VueEditor } from 'vue2-editor'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import ThumborImage from '@/components/general/ThumborImage.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  name: 'TipoVehiculos',
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
    ThumborImage,
    VueEditor,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    content: '',
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ color: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
    defaultButtonText: 'Cargar imagen del tipo de vehículo',
    dialog: false,
    dialog1: false,
    editedIndex: -1,
    hintTextDescripcion: 'Seleccione la ruta de localizacion',
    hintTextTipo: 'Seleccione la ruta de localizacion',
    isEditing: false,
    isLoading: false,
    isSelecting: false,
    isSure: false,
    overlay: false,
    search: undefined,
    selectedFile: null,
    tab: null,
    tab_large: null,
    headers: [
      { text: 'Imagen', value: 'imagen', width: '7%' },
      { text: 'Tipo', align: 'start', sortable: false, value: 'tipo' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    locales: [],
    tipos: [],
    defaultItem: {
      tipo: '',
      descripcion: '',
      descripcion_es: '',
      descripcion_en: '',
      descripcion_larga_es: '',
      descripcion_larga_en: '',
      imagen: '',
      imagenAnterior: '',
    },
    editedItem: {
      tipo: '',
      descripcion: '',
      descripcion_es: '',
      descripcion_en: '',
      descripcion_larga_es: '',
      descripcion_larga_en: '',
      imagen: '',
      imagenAnterior: '',
    },
    fields: {
      Tipo: 'tipo',
      Descripción: 'descripcion',
    },
  }),

  mixins: [validationMixin],
  validations: {
    editedItem: {
      tipo: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
    },
  },

  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
    // Propiedad computada para errores en campo descripción
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 70 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo descripción español
    descripcionEsErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion_es.$dirty) return errors
      !this.$v.editedItem.descripcion_es.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion_es.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion_es.maxLength &&
        errors.push('Este campo debe de tener un máximo de 70 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo descripción ingles
    descripcionEnErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion_en.$dirty) return errors
      !this.$v.editedItem.descripcion_en.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion_en.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion_en.maxLength &&
        errors.push('Este campo debe de tener un máximo de 70 caracteres.')
      return errors
    },

    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Tipo de Vehículo' : 'Editar Tipo de Vehículo'
    },

    // Propiedad computada para errores en tipo de Vehiculo
    tipoErrors() {
      const errors = []
      if (!this.$v.editedItem.tipo.$dirty) return errors
      !this.$v.editedItem.tipo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.tipo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.tipo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
  },

  async created() {
    this.obtenerLocales()
    this.obtenerTipos()
  },

  methods: {
    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de tipos de vehiculos
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * tipos de vehiculos
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de tipo de vehiculos
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.selectedFile = null
      this.changed = false
      this.tab = null
      this.tab_large = null
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.tipos.indexOf(item)
      confirm('Esta seguro que desea eliminar este registro?') && this.grupos.splice(index, 1)
      // TODO implementar borrado del registro
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar tipos de vehiculos
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.tipos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.ObtenerHintText(this.editedItem.tipo, 'tipo')
      this.ObtenerHintText(this.editedItem.descripcion, 'descripcion')
      this.dialog = true
    },

    /**
     * @description Obtiene el hint para un select mostrando las Traducciones
     * al idioma ingles y Español
     *
     * @param (string) valor Valor de la etiqueta a buscar
     * @param (string) tipo Valor del select para el que se genera el hint
     */
    ObtenerHintText(valor, tipo) {
      const resultado = this.locales.find((etiqueta) => etiqueta.etiqueta === valor)
      const en = resultado.locales.find((idioma) => idioma.clave === 'en')
      const es = resultado.locales.find((idioma) => idioma.clave === 'es')
      const resp = `(EN) ${en.valor} - (ES) ${es.valor}`

      switch (tipo) {
        case 'tipo':
          this.hintTextTipo = resp
          break
        case 'descripcion':
          this.hintTextDescripcion = resp
          break
        default:
          break
      }
    },

    /**
     * @description Obtiene un listado de todos los locales
     */
    async obtenerLocales() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('localization/fetchLocales', payload)
        .then((response) => {
          this.locales = response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
      // }
    },

    /**
     * @description Obtiene un listado de todos los tipos de vehiculos
     */
    async obtenerTipos() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('vehicleType/fetchVehicleTypes', payload)
        .then((response) => {
          this.tipos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
      // }
    },

    /**
     * @description Verifica que se haya cargado la imagen al servidor
     * desde que se hace click en el botón
     */
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },

    /**
     * @description Verifica si se cargo un archivo
     * y llama al método para cargarlo al servidor
     */
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.uploadFile()
      // do something
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo ya sea para un tipo de vehiculo
     * nuevo o la edicion de un tipo de vehiculo
     */
    async save() {
      if (this.editedItem.imagen === '' || this.editedItem.imagen === null) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'Es necesario cargar la imagen del vehiculo!',
        })
        return
      }

      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedItem.imagenAnterior !== '') {
          console.log('Borrar foto anterior')
        }
        let body = {
          tipo: this.editedItem.tipo,
          descripcion: this.editedItem.descripcion,
          descripcion_es: this.editedItem.descripcion_es,
          descripcion_en: this.editedItem.descripcion_en,
          descripcion_large_es: this.editedItem.descripcion_large_es,
          descripcion_large_en: this.editedItem.descripcion_large_en,
          imagen: this.editedItem.imagen,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('vehicleType/editVehicleType', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.tipos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('vehicleType/addVehicleType', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.tipos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.selectedFile = null
        this.close(this.checkbox)
      }
    },

    /**
     * @description sube un archivo al servidor
     */
    async uploadFile() {
      if (this.selectedFile != '') {
        let fd = new FormData()
        fd.append('attachment', this.selectedFile)
        let payload = { body: fd }
        this.$store
          .dispatch('vehicleType/uploadVehicleImage', payload)
          .then((response) => {
            // this.$store.commit('ALERT', {
            //   color: 'success',
            //   text: 'La imagen fue cargada con éxito',
            // })
            // this.editedItem.imagen = URL.createObjectURL(this.selectedFile)
            this.editedItem.imagenAnterior = this.editedItem.imagen
            this.editedItem.imagen = response.data.url
            // console.log(this.editedItem.imagen)
            this.$refs.uploader.value = ''
            this.isSelecting = false
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
          })
      } else {
        this.$store.commit('ALERT', {
          color: 'info',
          text: 'Seleccione una imagen',
        })
      }
    },
  },
}
</script>

<style>
.v-icon--left {
  margin-right: 8px;
}
</style>
